  /* App.css */

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");



/*Breaking points:

    Mobile : 320px to 480px
    Tablet : 481px to 768px
    Small screen laptops : 769px to 1024px
    Large screen laptops : 1025px to 1200px

*/


/*Media queries temaplate
      @media (min-width: 1025px) and (max-width: 1200px){ 
        
        .hero h1 {
        }
      }
      @media (min-width: 320px) and (max-width: 480px){ 
        
        .hero h1 { }
      }
      
      @media (min-width: 481px) and (max-width: 768px){ 
      
        .hero h1 {}
      }
      
      @media (min-width: 769px) and (max-width: 1024px){ 
        
        .hero h1 {}
      }
      @media (min-width: 1025px) and (max-width: 1200px){ 
      
      .hero h1 {
        
      }
      }
*/


/*BODY RELATED*********************************************************************/

  body {
    margin: 0;
    font-family: Rubik, sans-serif;
    overflow-y: hidden;
    }

/*NAV RELATED*********************************************************************/
  
  .nav-click {



    font-family: caveat ;
    font-weight: 700;
    font-size: 20pt;
    color: #000000;
    margin-left: 0px;
    width: 100px;
    rotate: 5deg;

    


    
  }



    

  nav {
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffffb3;
    color: white;
    padding: 0;
    z-index: 1000;
    }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: right;
    margin: 0;
    flex-wrap: wrap;
    }

      @media (max-width: 480px) {
        nav ul {
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-around;
          }
        }

  nav ul li {
    margin: 0 15px;
    flex-wrap: nowrap;
    }

    @media (max-width: 480px) {
      nav ul li {
        margin: 0 5px;
        width: min-content;
        }
      }

  nav li {
    width: 160px;
    }

  nav li:hover {
    scale: 1.1;
    }

  nav li:active {
    scale: 1.2;
    font-weight: bold;
    }

  nav ul li a {
    color: white;
    text-decoration: none;
    padding: 0px 20px;
    display: block;
    text-align: center;
    }

    @media (max-width: 480px) {
      nav ul li a {
        padding: 0;
        font-size: 11pt;
        }
      }

  .navbar {
    margin: auto;
    max-width: 1050px;
    width: 100%;
    box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
    }

  .nav-container {
    display: flex;
    position: relative;
    height: 48px;

    @media (min-width: 1201px) and (max-width: 2200px){ 
      
      position: none;
        
      }
    }

  .navbar .nav-container li {
    list-style: none;
    }

  .navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
    }

  .navbar .nav-container a:hover {
    font-weight: bolder;
    }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    }

  .nav-container .hamburger-lines {
    height: 23px;
    width: 29px;
    position: absolute;
    top: 13px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }

  .nav-container .hamburger-lines .line {
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
    }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
    }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
    }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
    }

  .nav-container .title {
    position: relative;
    left: 70px;
    top: 12px;
    height: 20px;
    font-size: 16pt;
    color: #000000;
    }
  .navbar .menu-items {
    padding-top: 95px;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: 0px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.684);
    }

  .navbar .menu-items li {
    margin-left: 50px;
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: lighter;
    text-shadow: 0px 0px 5px rgb(255, 255, 255);
    text-align: left;
    }

  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
    }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
    }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
    }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
    }

  .nav-container input[type="checkbox"]:checked ~ .logo {
    display: none;
    }

/*SECTION RELATED*********************************************************************/
  .scroll-container {
      display: block;
      overflow-x: hidden;
      scroll-snap-type: y mandatory;
      width: 100vw;
      height: 100vh;
    }
  .section {
    
    width: 100vw;
    height: 100vh;
    display: flex;
  
    
    align-items: center;
    justify-content: center;
    font-size: 2em;
    flex-shrink: 0;
    scroll-snap-align: start;


    }

    #section1 { background: rgb(255, 255, 255); }
    #section2 {
      background: repeating-linear-gradient(45deg, #92c9b1, #92c9b1 20px, #b3e0d2 20px, #b3e0d2 40px);

      }
    #section3 { background: #e74c3c; }
    #section4 { 
        
        width: 100%;
        height: 100%;

        align-items: flex-start;
        
        
        /* Add your background pattern here */
        
        background-color: #e5e5f7;
        background-image: 
        radial-gradient(#444cf7 10%, transparent 10%),
        radial-gradient(#444cf7 10%, transparent 10%);
        
        background-size: 100px 100px;
        background-position: 0 0, 50px 50px;
      }
    #section5 { 
        background: #ffffff; 

      }

  
/*HEROPAGE RELATED*********************************************************************/


      .hero { /*This class is to fill whole section and center all the items in it   */
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;

        height: 100vh;
        width: 100vw;

        background: url('images/player.jpg') no-repeat center center/cover;
        background-image: flip;
        
        color: white;
        text-align: center;
        padding: 0 10px; /* Ensuring some padding on the sides */
        
        flex-direction: column;
        max-width: 1200px;
        width: 100%;
        
        margin-top: 0;
        
        @media (max-width: 480px) {
          background: url('images/player.jpg') no-repeat center center/cover;
          
        }
        
        @media (min-width: 1025px) and (max-width: 1200px){ 
          
          max-width: 2200px;

        }
        @media (min-width: 1201px) and (max-width: 2200px){ 
          
          max-width: 2200px;

        }

      }

      .hero-headline-container {
        display: flex;
        flex-direction: row;
        width: auto;
        align-self: flex-start;
        margin-left: 36px;

        @media (max-width:480px) {
          align-self:center;
          width: 90%;
        }
        }
     
      .hero h1 {

        margin-block-start: 0em;
        margin-block-end: 0em;
        width: 400px;
        z-index: 10;

        text-align: left;


        margin-top: 10px;
        margin-bottom: 25px;
        font-weight: bolder;
        text-shadow: 0px 0px 40px #00000032;
        
        


        @media (min-width: 320px) and (max-width: 480px){ 
        
            .hero h1 {
            font-size: 50pt;    
            text-shadow: 0px 0px 40px #000000;
            text-align: center;
            margin-bottom:30px;
            }
        }

        @media (min-width: 481px) and (max-width: 768px){ 
        
            .hero h1 {
            font-size: 50pt;    
            text-shadow: 0px 0px 40px #000000;
            text-align: left;
            margin-bottom:20px;
            }
        }
        
        @media (min-width: 769px) and (max-width: 1024px){ 
        
            .hero h1 {
            font-size: 50pt;    
            text-shadow: 0px 0px 40px #000000;
            text-align: left;
            margin-bottom:20px;
            }
        }
        @media (min-width: 1025px) and (max-width: 1800px){ 
        
            .hero h1 {
            font-size: 50pt;    
            text-shadow: 0px 0px 40px #000000;
            text-align: left;
            margin-bottom:20px;
            }
        }
      }

        /*
        @media (min-width: 1025px) and (max-width: 1200px){ 
          
          .hero h1 {
          }
        }
        @media (min-width: 320px) and (max-width: 480px){ 
          
          .hero h1 { }
        }
        
        @media (min-width: 481px) and (max-width: 768px){ 
        
          .hero h1 {}
        }
        
        @media (min-width: 769px) and (max-width: 1024px){ 
          
          .hero h1 {}
        }
        @media (min-width: 1025px) and (max-width: 1200px){ 
        
        .hero h1 {
          
        }
        }
          */
      .hero-text-bubble {
        background-color: #ffffffb3;
        backdrop-filter: blur(1px);
       
        align-self: flex-start;
        
        width: 40vw;
        padding: 5px;
        
        margin: 36px;
        margin-bottom: 20px;
        border-radius: 10px;
        
        @media (max-width: 480px) {
          width:90vw;
          margin: 20px;
          margin-bottom:10px;
          margin-top:10px;
          align-self: center;
          }
        }

      .hero p {
          margin-block-start: 0em;
          margin-block-end: 0em;

          margin: 10px;
          font-size: 16px;
          
          text-align: left;
          color: black;
          
        }
      


      .button-container{ 
        display: flex;
        align-self: flex-start;
        }

      .button {

          display: flex;
          align-items: center;
          justify-content: center;

          z-index: 10;

          width: 140px;
          height: 45px;
          
          background: rgb(251, 255, 0);
          color: black;
          border-radius: 1em;
          border-color: black;
          border-style: solid;
          border-width: 3px;
          
          box-shadow: 5px 5px black;
          
          margin: 20px;
          margin-left: 36px;
          margin-top: 0px;
          margin-bottom: 40px;
        }

          
   
        @media (min-width: 320px) and (max-width: 480px){ 
          
          .button {
            margin-left:20px;
           }
        }
        
        @media (min-width: 481px) and (max-width: 768px){ 
        
          .button {}
        }
        
        @media (min-width: 769px) and (max-width: 1024px){ 
          
          .button {}
        }
        @media (min-width: 1025px) and (max-width: 1200px){ 
        
        .button {
          
        }
        }
          

      .button:active {
          box-shadow: none;
          transform: translate(5px, 5px);
        }
      .button-text {
        font-family: Rubik;
        font-size: 12pt;
        font-weight: bold;
        color: black;
        }
  

  

/*FEATURESPAGE RELATED*********************************************************************/

  .features {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
   /*
   background: repeating-linear-gradient(45deg, #92c9b1, #92c9b1 20px, #b3e0d2 20px, #b3e0d2 40px);
   */ 
    
    z-index: 10;
    
    @media (max-width: 480px) {
      align-items: start;
      padding-top: 30px;
      z-index: 10;


    }
    }
  .features-content {
    display: flex;
    flex-direction: row;
    max-width: 90vw;
    width: 100%;
    text-align: center; /* Align content center */

  
      
    }


    

    @media (min-width: 320px) and (max-width: 480px){ 
        
      .features-content { 
        flex-direction:column;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top:30px;
    }
  }
  
  @media (min-width: 481px) and (max-width: 768px){ 
    
   
      .features-content { 
      flex-direction:column;
      display: flex;
      align-items: center;
      justify-content: center;
    
      width: 90vw;
      margin-top:30px;
    
      
    }
    }
    
    @media (min-width: 769px) and (max-width: 1024px){ 
      
      .features-content {
     
      }
    }
    @media (min-width: 1025px) and (max-width: 1200px){ 
    
    .features-content {
      
    }
    }



  .card {
      width: 25vw;
      height: 25vw;
      background: white;
      border-radius: 1em;
      border-color: black;
      border-style: solid;
      border-width: 3px;
      box-shadow: 10px 10px black;
      margin: 20px;

  }
    
      @media (min-width: 320px) and (max-width: 480px){ 
        
        .card { 
          flex-direction:column;
          width: 300px;
          height:150px;}
      }
      
      @media (min-width: 481px) and (max-width: 768px){ 
      
        .card {
          flex-direction:column;
          height:auto;
          width: 70vw;
        }
      }
      
      @media (min-width: 769px) and (max-width: 1024px){ 
        
        .card {
          flex-direction:column;
          height:auto;
        }
      }
      @media (min-width: 1025px) and (max-width: 1200px){ 
      
      .card {
        
      }
      }
 

  .features-headline-container {
    display: flex;
    flex-direction: row;
    width: auto;
    }




  .featuresbox {
      width: 25vw;
      height: 25vh;
      background-color: orange;
      border: 4px solid darkorange;
      border-radius: 10px;
      margin: 10px;
      box-shadow: 10px 5px 5px rgb(0, 0, 0);
      
     
    }
  .featuresbox-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px;
      @media (max-width: 480px) {
        margin: 15px;
        margin-top:10px;

      }
    }
  .featuresbox-container h1 {
      
      margin-block-start: 0em;
      margin-block-end: 0em;
      font-size: 12pt;
      margin-top: 0;
      margin-bottom: 10px;
    }

  .featuresbox-container p {

    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    
    font-size: 11pt;
    text-align: left;
    }




/*TEACHERPAGE RELATED*********************************************************************/


  .teacher { /*Again, this is class for container which fills the section, all child items are aligned at start*/
    display: flex;

    align-content: flex-start;    
    justify-content: flex-start;
    
    height: 100vh;
    width: 100vw;
    
    background: url('images/grass.jpg') no-repeat center center/130% ;
    
    color: white;

    padding: 0 10px; /* Ensuring some padding on the sides */
    
    
    @media (max-width: 480px) {
      background: url('images/grass.jpg') no-repeat center center/300% ;

      }
    @media (max-width: 768px) {
      background: url('images/grass.jpg') no-repeat center center/cover ;

      }
    }

  .teacher-content { /*Container to move all the content in the section */
      display: flex;
      flex-direction: row;

      align-items: flex-start;
      justify-content: flex-start;
      
      width: 100%;
      text-align: center;
      
      margin-top: 50px;
 

    }

  
    @media (min-width: 320px) and (max-width: 480px){ 
      
      .teacher-content{
        flex-direction: column;
        margin-top:0px;
       }
    }
    
    @media (min-width: 481px) and (max-width: 768px){ 
    
      .teacher-content{
        flex-direction: column;
      }
    }
    
    @media (min-width: 769px) and (max-width: 1024px){ 
      
      .teacher-content{}
    }
    @media (min-width: 1025px) and (max-width: 1200px){ 
    
  .teacher-content{
    
      }
      }

  .teacher-headline-container { /*Container to align and move title + text as a set*/
      display: flex;
      justify-self:flex-start;
      align-self: flex-start;

      width: auto;        
      
      margin-top: 80px;

      align-items: center;
      justify-content: center;
      
      
      @media (max-width: 480px) {
        margin-top:50px;
        
      }

    }

  .teacher-headline-container p { /*Text format*/
      display: none;
    }
  .teacher h1 { /*Title format, alignment etc*/

      margin-block-start: 0em;
      margin-block-end: 0em;
      
      width: 100%;
      
      font-size: 50pt;
      text-shadow: 2px 2px 40px #000000;
      text-align: center;
      margin-left: 36px;
      
        
      display: flex;
      align-items: center;
      justify-content: left;
      
      flex-wrap: wrap;
      
      
      
      @media (max-width: 480px) {
        
        justify-content:center;
     
        margin:0px;
   
        flex-direction: row;
        flex-wrap: wrap;
        width: 100vw;
      }
      @media (max-width: 768px) {
        
        font-size: 50pt;
      }
    }
  .teacher-cards-container {
    display: flex;
    margin-block-start: 0em;
    margin-block-end: 0em;;
    align-items:flex-start;
    justify-content:center;
    
    flex-wrap: wrap;

    @media (max-width: 480px) {
    }  
    @media (max-width: 768px) {
      margin-top: 10px;

    }
    }

    @media (min-width: 320px) and (max-width: 480px){ 
      
      .teacher-cards-container{ }
    }
    
    @media (min-width: 481px) and (max-width: 768px){ 
    
      .teacher-cards-container{}
    }
    
    @media (min-width: 769px) and (max-width: 1024px){ 
      
      .teacher-cards-container{
        margin-top:  50px;
      }
    }
    @media (min-width: 1025px) and (max-width: 1200px){ 
    
  .teacher-cards-container{
    margin-top:  50px;

    }
    }


/*TEACHERPAGE CARD RELATED*********************************************************************/
  
    
      
  

   
 
    .teacher-card-main-info-container {
      
      font-family: Caveat;
      color: black;
      width: 80px;
      height: max-content;
      margin-top: 15px;
      font-size: 16pt;
      align-self:center;   

      
      
      @media (max-width: 480px) {
        
        font-weight: bold;
        font-size: 12pt;
        
        width: 80px;
      }
      }




/*FLIPPING CARD RELATED*********************************************************************/

    .flipping-card { /* This will define the size of the card and the perspective*/
      width: 150px;
      height: 150px;
      perspective: 1000px;

      margin: 8px;

      }

    
    .flipping-card img { /* This will define how the image fills its container */
   
        height: 100%;


        }


    .flipping-card .card-front,
    .flipping-card .card-back { /* This will define the front AND back plus adds invisibilty */
      display: flex;
      justify-content: center;
      justify-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: hidden;
      }
    
    .flipping-card .card-front { /* This will define the card front background */
      display: flex;
      justify-content: flex-start;

            

      background-color: #f1f1f1;
      border-radius: 10px;
      transform: rotateY(0deg);

      }
    
  
  
    .flipping-card .card-back { /* This will define the card back background */
      background-color: #e1e1e1;
      border-radius: 10px;
      border: 5px solid white;

      box-sizing: border-box;

      transform: rotateY(180deg);

      overflow: hidden;


      }
    
    /*When flipping card has a   */
    .flipping-card.flipped .card-front {
      transform: rotateY(-180deg);
      }
    
    .flipping-card.flipped .card-back {
      
      transform: rotateY(0deg);
      }
    
    /* Add transitions for smooth flipping animation */
    .flipping-card .card-front,
    .flipping-card .card-back {
      transition: transform 0.5s ease;
      }
  
/*SCHEDULEPAGE RELATED*********************************************************************/

    .schedule-page-container{

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 80px;
      font-size: 24pt;
      color: #000000;
      }
    .schedule-page-title{

      margin-block-start: 0em;
      margin-block-end: 0em;

      margin-bottom: 10px;

      width: 100%;
      
      font-size: 50pt;
      text-shadow: 2px 2px 4px #000000aa;
      text-align: center;
      color: white;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 50pt;
 
 
          }
 
     }
    .schedule-text-bubble {
      


      background-color: #fecaca;
      padding: 5px;
      margin: 36px;
      margin-bottom: 20px;
      margin-top: 10px;
      border-radius: 10px;

      border: 8px solid white;
      
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.533);
      
      @media (max-width: 768px) {
        width: 80vw;
        }
      
      }
    .schedule-title{


      font-weight: bold;
      line-height: 1.6;

      margin: 10px;
      }
    .schedule-text-bubble  > ul {
      
      
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0px;
      

      list-style: none;
      font-size: 16pt;
      margin: 10px;   
          }

    ul > li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
        }
    
    

    .schedule-info-item {
      display: flex;
      align-items: center;
            }
  
    .check-bubble {
      width: 20px;
      height: 20px;
      background-color: #FF378D; /* Green color for the bubble */
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
        }
  
    .check-bubble::before {
      content: '✓';
      color: white;
      font-size: 14px;
             }

    .schedule-info-check-bubble{
      width: 12pt;
      height: 12pt;
      }  

    .schedule-info-title {

      font-weight: bold;
      font-size: 16pt;

      margin-block-start: 0em;
      margin-block-end: 0em;
      font-size: 12pt;
      margin-top: 0;
      margin-bottom: 0px;
      
      }
    .schedule-info-data {

      width: 190px;


      font-size: 12pt;

      text-align: left;

      margin-right: 20px;
      
      }

/*GOOGLEFORM RELATED*********************************************************************/
  .googleform-container { 

        }

  iframe { 
    margin-top: 150px;
    }